<template>
    <div class="sitemap-index">
        <h1 class="sitemap-index__title">{{ title }}</h1>
        <div class="sitemap-index__content">
            <nav v-if="$route.name !== 'sitemap-promociones-slug'" class="sitemap-index__tabs">
                <NuxtLink to="/sitemap" class="sitemap-index__tab-item">Categorías</NuxtLink>
                <NuxtLink to="/sitemap/tiendas" class="sitemap-index__tab-item">Tiendas</NuxtLink>
                <NuxtLink to="/sitemap/promociones" class="sitemap-index__tab-item">Promociones</NuxtLink>
            </nav>

            <NuxtPage />
        </div>
        <div class="py-2">
            <LayoutBreadcrumbs :layers="breadcrumbs" text-color="text-gray-900" />
        </div>
    </div>
</template>

<script lang="ts" setup>
const title = computed(() => {
    return Route.name === 'sitemap-index'
        ? 'Categorías'
        : Route.name === 'sitemap-index-tiendas'
          ? 'Tiendas'
          : 'Promociones'
})

const { $lang } = useNuxtApp()
const metaInfo = {
    title: `Mapa del sitio - ${$lang.brand}`,
    description: `Mapa del sitio de ${$lang.brand}. Consulta categorías, tiendas, cupones, ofertas y promociones de forma ordenada y accesible.`,
}

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()

useSeoMeta({
    title: metaInfo.title,
    ogUrl: origin + Route.path,
    ogTitle: metaInfo.title,
    description: metaInfo.description,
    ogDescription: metaInfo.description,
    ogImageAlt: metaInfo.title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

const breadcrumbs = computed(() => {
    const fragment =
        Route.path === '/sitemap'
            ? [
                  {
                      path: '/sitemap',
                      title: 'Categorías',
                  },
              ]
            : Route.path === '/sitemap/tiendas'
              ? [
                    {
                        path: '/sitemap/tiendas',
                        title: 'Tiendas',
                    },
                ]
              : Route.path === '/sitemap/promociones'
                ? [
                      {
                          path: '/sitemap/promociones',
                          title: 'Promociones',
                      },
                  ]
                : [
                      {
                          path: '/sitemap/promociones',
                          title: 'Promociones',
                      },
                      {
                          title:
                              (Route.params.slug.slice(0, 1) as string).toUpperCase() +
                              Route.params.slug.slice(1),
                      },
                  ]
    return [
        {
            path: '/',
            title: $lang.pages.forosSlug.home,
        },
        ...fragment,
    ]
})
</script>

<style lang="postcss">
.sitemap-index {
    @apply container;
    &__title {
        @apply py-5 text-2xl font-semibold;
    }
    &__content {
        @apply rounded-lg bg-white p-6 md:px-12 md:py-16 lg:py-20 xl:px-16;
    }
    &__tabs {
        @apply mb-5 flex h-12 w-full items-center border-b border-gray-200;
    }
    &__tab-item {
        @apply flex h-full flex-grow items-center justify-center border-b-4 border-transparent font-semibold transition-colors;
        &.router-link-exact-active {
            @apply border-site-primary text-site-primary;
        }
    }
}
</style>
